@import "../../../../variables.scss";
#data-policy-violation {
  height: 270px;
  .nav-pills .nav-item a {
    position: relative;
    .hacker-icon {
      position: absolute;
      right: 0px;
      top: 4px;
    }
    height: 100%;
    color: $grey-shade;
    border-bottom: 1px solid $border-color-v1-4;
  }
  .nav-pills li a.active {
    color: $white-color;
    background-color: unset;
    border-bottom: 1px solid $toggle-button-color;
  }
  .nav-pills .nav-link {
    border-radius: unset;
    border-bottom: 1px solid;
  }
  .nav-pills .nav-item a {
    color: $filter-text-color;
  }
  .nav-item-all {
    // width: auto;
  }
  .nav-filters {
    // width: auto;
  }
  .nav-actions {
    // width: auto;
    margin-left: auto;
    cursor: pointer;
    .filter-icon-disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
  .beta {
    width: 25px;
    height: 13px;
    background-color: $beta-tag-color;
    border-radius: 8px;
    font-size: 11px;
    padding: 0px 7px;
    font-weight: 600;
    color: $text-highlight-color;
  }
  .nav-pills li {
    a {
      //   padding: 0px;
      padding: 10px 0px;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
    }
  }
  ul {
    list-style: none;
    white-space: nowrap;
  }
  ul > li {
    display: inline-block;
  }
  .table-responsive {
    height: 200px;
  }
  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    color: $white-color;
  }
  tbody {
    tr {
      background: none;
      margin-bottom: 4px;
      cursor: unset;
      td {
        height: 25px;
        padding-left: 0px;
        color: $table-cell-text-color-v1;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        border-bottom: 1px solid $border-color-v1;
        .icon {
          margin-top: -3px;
          margin-left: 5px;
        }
      }
      .sensitivity-count {
        text-align: end;
        font-weight: 700;
        font-size: 16px;
        color: $white-color;
        width: 60px;
      }
    }
  }
}
