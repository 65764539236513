@import "../../variables.scss";

.settings-container {
  .card-action-images {
    height: 22px;
    margin-right: 5px;
    padding: 1px 1px 1px 1px;
    object-fit: contain;
    cursor: pointer;
  }

  .integration {
    margin-bottom: 10px;

    .integration-title {
      span {
        color: $card-heading-color;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        padding: 10px 0;
      }
    }
    h6 {
      margin-bottom: 10px;
      margin-left: 20px;
      margin-top: 10px;
    }
  }
  .create-btn {
    background: #6c83bb;
    border: none;
    margin-left: 20px;
    margin-top: 10px;
  }

  .account {
    .wiz-background {
      background: $wiz-blue !important;
    }
    .iq-card {
      background-color: $midnight-express-1;
      margin-bottom: 0px;
      border-radius: 0px;
      .card-body {
        img {
          height: 25px;
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 50%;
        }
      }
    }
    .account-card {
      .footer {
        .primary {
          color: $white-color;
          font-size: 12px;
          margin-top: 15px;
          margin-left: 10px;
        }

        .secondary {
          color: $grey-shade;
          font-size: 13px;
          margin-top: 15px;
          margin-left: 10px;
        }

        .danger {
          color: #c83939;
          font-size: 13px;
          margin-top: 15px;
          margin-left: 10px;
        }
        display: flex;
        justify-content: space-between;
        div {
          img {
            width: 25px;
            margin: 12px 5px;
          }
          i {
            color: $white-color;
            margin: 15px 5px;
            font-size: 22px;
            cursor: pointer;
          }
        }
      }
      .row {
        height: 40px;
        // border-bottom: 1px solid lightgray;
        border-top-right-radius: 5px;
      }
      .card-body {
        padding: 1rem;
        // border-bottom: 1px solid lightgray;
        // height: 50px;

        .lab {
          color: #6c83bb;
          font-size: 13px;
          font-weight: bold;
          margin-right: 2px;
        }
        h6 {
          font-weight: bold;
          font-size: 12px;
          color: $grey-shade;
          img {
            width: 30px;
            margin-right: 5px;
          }
        }
      }
      border-radius: 5px;
      box-shadow: none;
      .connect-cards {
        h6 {
          font-weight: bold;
          color: gray;
          font-size: 14px;
        }
      }
      div {
        .check {
          // background: #6c83bb;
          border-top-left-radius: 5px;
          i {
            margin-top: 10px;
          }
        }

        .check-danger {
          background: #c83939 !important;
        }

        .check-secondary {
          background: #919191 !important;
        }

        div {
          .card-title-primary {
            margin-top: 10px;
            font-weight: bold;
            color: rgb(100, 137, 217);
            font-size: 14px;
          }

          .card-title-secondary {
            margin-top: 10px;
            font-weight: bold;
            color: $grey-shade;
          }

          .card-title-danger {
            margin-top: 10px;
            font-weight: bold;
            color: #c83939;
          }
        }
      }
      .iq-card-header {
        padding: 0px;
        height: 33px;
      }
    }
  }
}
.policy-settings {
  h6,
  h5 {
    color: $white-color;
    font-weight: 500;
  }
  .chip-container {
    display: block;
    .workday-subheading {
      min-width: 140px;
    }
  }
  .create-btn {
    background-color: $sub-heading-color;
    border: none;
    width: 110px;
    height: 35px;
    font-family: $manrope-regular-font;
    padding: 0;
  }
  .heading-section {
    h6 {
      color: $white-color;
    }
  }
  .table-scroll {
    max-height: 800px;
    overflow: scroll;
  }
  .alerting-policy-table {
    width: 100%;
    font-size: 12px;
    border-collapse: collapse;

    tbody {
      tr {
        background-color: rgba(0, 0, 0, 0.05);
        color: #777d74;
        font-weight: 500;
        td {
          .ri-checkbox-circle-fill {
            font-size: 2em;
          }
          .ri-close-circle-fill {
            font-size: 2em;
          }
          .chip-container .chip {
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $sub-heading-color;
            // background-color: $white-color;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            padding: 3px 3px 3px 5px;
            border: 1px solid $sub-heading-color;
            width: fit-content;
            overflow: hidden;
          }
        }
      }
    }
    tr {
      background-color: unset;
    }
    th:first-child {
      padding-left: 10px;
    }
    th:last-child {
      padding-right: 10px;
    }
    th {
      padding: 5px;
    }
    tr td:nth-child(-n + 11) {
      border-bottom: unset;
      padding-right: 10px;
      padding-left: 5px;
    }
    tr td:first-child {
      padding-left: 10px;
    }
    tr td:last-child {
      padding-right: 10px;
    }
    .action-buttons {
      font-size: 14px;
      color: $sub-heading-color;
    }
  }
  .alerting-policy-title {
    font-size: 14px;
  }
  .automation-policy-table {
    width: 100%;
    font-size: 12px;
    border-collapse: collapse;
    tbody {
      tr {
        background-color: rgba(0, 0, 0, 0.05);
        color: #777d74;
        font-weight: 500;
      }
    }
    tr {
      background-color: unset;
    }
    th:first-child {
      padding-left: 10px;
    }
    th:last-child {
      padding-right: 10px;
    }
    th {
      padding: 5px;
    }
    tr td:nth-child(-n + 11) {
      border-bottom: unset;
      padding-right: 10px;
      padding-left: 5px;
    }
    tr td:first-child {
      padding-left: 10px;
    }
    tr td:last-child {
      padding-right: 10px;
    }
    .action-buttons {
      font-size: 14px;
      color: $sub-heading-color;
    }
  }
  .automation-policy-title {
    font-size: 14px;
  }
}
