@import "../../variables.scss";

.tabs {
  ul,
  li {
    display: inline-block;
    .custom-nav-link {
      padding: 0.5em 2em;
      border-bottom: 0.1px solid $tab-border;
      cursor: pointer;
      color: #ffffff;
    }
    .active {
      border-bottom: 2px solid $toggle-button-color;
      margin-top: -10px;
      cursor: auto;
    }
    .nav-link-disable {
      opacity: 0.5;
      padding: 0.5em 2em;
      border-bottom: 0.1px solid $tab-border;
      cursor: default;
      color: #ffffff;
    }
  }
}
