@import "../../variables.scss";

.llm-container {
  .active-star-svg {
    color: red;
  }
  #card-layout {
    .btn-disable {
      opacity: 0.5;
    }
    .launch-loader {
      width: 15px;
    }
    .table-responsive {
      .table thead tr th {
        background: #251d43;
        padding: 1em;
      }
      // th:nth-child(2) {
      //   text-align: center;
      // }
      .table tbody tr {
        cursor: default;
        background: none !important;
        td {
          padding: 2px 14px;
          img {
            width: 20px;
            border-radius: 50%;
          }
        }
        // td:nth-child(2) {
        //   text-align: center;
        // }
      }
    }
    .instance-table {
      tr td {
        width: 50%;
      }
    }
    margin-top: 1em;
    .left-container {
      .filter-color {
        width: 10px;
        height: 10px;
        display: block;
        border-radius: 50%;
        margin: auto;
        margin-right: 5px;
        &-configured {
          background-color: $dark-blue;
        }
        &-access-path {
          background-color: $access-path-filter;
        }
        &-live-access {
          background-color: $live-access-filter;
        }
      }
      .nav-pills .nav-item a {
        position: relative;
        .hacker-icon {
          position: absolute;
          right: 0px;
          top: 4px;
        }
        height: 100%;
        color: $grey-shade;
        border-bottom: 1px solid $border-color-v1-4;
      }
      .nav-pills li a.active {
        color: $white-color;
        background-color: unset;
        border-bottom: 1px solid $toggle-button-color;
      }
      .nav-pills .nav-link {
        border-radius: unset;
        border-bottom: 1px solid;
      }
      .nav-pills .nav-item a {
        color: $filter-text-color;
        display: flex;
      }
      .nav-filter-disable {
        opacity: 0.5;
      }
      .nav-actions {
        // width: auto;
        margin-left: auto;
        cursor: pointer;
        .filter-icon-disabled {
          opacity: 0.5;
          cursor: default;
        }
      }
      .beta {
        width: 25px;
        height: 13px;
        background-color: $beta-tag-color;
        border-radius: 8px;
        font-size: 11px;
        padding: 0px 7px;
        font-weight: 600;
        color: $text-highlight-color;
      }
      .nav-pills li {
        a {
          //   padding: 0px;
          padding: 10px 0px;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
        }
      }
      ul {
        list-style: none;
        white-space: nowrap;
      }
      ul > li {
        display: inline-block;
      }
      .ml-15 {
        margin-left: -15px;
      }
      color: $white-color;
      h6 {
        color: $white-color;
      }
      .title {
        img {
          width: 30px;
          float: right;
          margin-right: 2em;
        }
        font-size: 18px;
        margin-bottom: 1em;
        color: $white-color;

        .title-info {
          opacity: 0.3;
          font-size: 14px;
          font-weight: 100;
        }
      }
      .key {
        padding: 0;
        font-size: 16px;
        opacity: 0.3;
      }
      .value {
        font-size: 16px;
        color: $white-color;
      }
    }
    .right-container {
      .policy-violation-selected {
        box-shadow: 0px 0px 2px 1px $live-access-filter;
      }
      color: $white-color;
      h6 {
        color: $white-color;
      }
      img {
        width: 40px;
      }

      .info {
        margin-top: 0.5em;
        color: $white-color;
        font-size: 16px;
        .model-hash {
          opacity: 0.3;
        }
        .hash {
          margin-left: 0.5em;
          opacity: 1;
        }
      }
      .info-no-margin {
        margin-top: 0;
      }
      .title {
        color: $white-color;
        font-size: 15px;
        margin-bottom: 1em;
      }
      .score-title {
        margin-left: 10px;
        opacity: 0.3;
        font-size: 16px;
      }
      .score-value {
        color: $white-color;
        margin-left: 2.5em;
        font-size: 26px;
        font-weight: 700;
        margin-top: -0.5em;
      }
      .title {
        color: $white-color;
        font-size: 18px;
        .title-info {
          opacity: 0.3;
          font-size: 12px;
          font-weight: 100;
        }
      }
    }
  }
}
