@import "../../variables.scss";
.datastore {
  &-card {
    border: 1px solid rgba(211, 211, 211, 1);
    cursor: pointer;
    background-color: $card-background-color;
    &-icon {
      float: right;
    }
    &-text {
      font-size: 48px;
    }
    &-title {
      font-size: 14px;
    }
  }
}
.table-vulnerabilities {
  .table-scroll {
    height: 300px;
  }
  .table-scroll-datastore-info {
    max-height: 280px;
  }
  .table-responsive {
    .table {
      border-collapse: separate;
      border-spacing: 0 7px;
      table-layout: fixed;
      &-card {
        padding: 0 25px 10px 25px;
      }
      &-data {
        font-family: $manrope-regular-font;
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        color: $subtext-color !important;
      }
      &-type {
        color: $subtext-color !important;
      }
      .subvalue-bold {
        font-family: $manrope-regular-font;
        font-weight: 650;
        font-style: normal;
        font-size: 0.875rem;
        color: $sub-heading-color;
        text-align: left;
      }
      tbody {
        tr {
          &:hover {
            .row-id {
              background-color: rgba(200, 57, 57, 1);
              color: $white-color;
            }
          }
        }

        .Medium {
          &:hover {
            .row-id {
              background-color: rgb(249, 160, 76);
              color: $white-color;
            }
          }
        }

        .Low {
          &:hover {
            .row-id {
              background-color: rgb(9, 171, 235);
              color: $white-color;
            }
          }
        }
      }

      thead {
        th {
          border: hidden;
          text-align: left;
          position: relative;
          padding-right: 15px;
        }
        tr {
          color: $subtext-color;
        }
      }
      tr {
        cursor: pointer;

        td {
          height: auto;
          text-align: left;
          padding: 5px;
          font-size: 12px;
          &:nth-child(-n + 11) {
            // border: 1px, 1px, 0, 1px solid rgba(211, 211, 211, 1);
            border-top: 1px solid rgba(211, 211, 211, 1);
            border-bottom: 1px solid rgba(211, 211, 211, 1);
          }
          &:first-child {
            border-bottom-left-radius: 10px;
          }
          &:nth-last-child(3) {
            border: 1px solid rgba(211, 211, 211, 1);
          }
          &:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            border-right: 1px solid $card-border-color;
          }
          .ticket-image {
            opacity: 0.6;
          }
        }
      }
      .wrap-with-ellipsis {
        width: 220px;
      }
      .wrap-with-ellipsis-datastore-info {
        u {
          color: $text-primary;
        }
        width: 170px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .wrap-title {
        width: 140px;
      }
      .wrap-title-datastore-info {
        width: 140px;
      }
    }
  }
  .modal {
    background: rgba(0, 0, 0, 0.1) !important;
  }
}

.row-id {
  background-color: rgba(200, 57, 57, 0.6980392156862745);
  color: $white-color !important;
  text-align: center !important;
  font-size: 16px !important;
}

.High {
  background-color: rgba(200, 57, 57, 0.6980392156862745);
  color: $white-color !important;
  text-align: center !important;
  font-size: 16px !important;
}

.Medium {
  background-color: rgba(242, 172, 10, 0.698);
  color: $white-color !important;
  text-align: center !important;
  font-size: 16px !important;
}

.Low {
  background-color: rgba(143, 187, 240, 0.698);
  color: $white-color !important;
  text-align: center !important;
  font-size: 16px !important;
}

.head {
  border-top: hidden;
}

.pagination {
  &-btn {
    color: $white-color;
    border: none;
    color: $white-color;
    padding: 5px 7px;
    font-size: 14px;
    margin: 5px;
    cursor: pointer;
    border-radius: 50%;

    &-disable {
      @extend .pagination-btn;
      cursor: not-allowed;
    }
    &-icon {
      margin-top: 10px;
    }
  }
}

.dropdown-title {
  float: left;
}
.dropdown-toggle::after {
  float: right;
  margin: 7px 0 7px;
}

.card-main {
  margin: 0 10px 0 10px;
}

.data-store-name {
  color: $text-primary !important;
  font-weight: 400 !important;
  font-style: normal;
  b {
    color: $white-color;
  }
}
.table-dropdown {
  width: 140px;
  height: 31px;
  background: inherit;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.red-dot {
  font-size: 10px;
}

.ticket-icon {
  width: 25px;
}
.ticket-icon-disable {
  width: 25px;
  opacity: 0.5;
  cursor: unset !important;
}
